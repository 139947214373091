import { getItem, postItem, putItem, deleteItem } from '@/api/api-methods';
import { getFullName } from '../../shared/helpers/dataUtils';
import { deleteItemWithOptions } from '../../../api/api-methods';
const url = '/app-personas/plan-estudios';

export const fetchSyllabus = async ({
  institutionId = null,
  name = null,
  startDay = null,
  endDay = null,
  schoolLevel = null,
  officialSubjectsIds = null,
  extraSubjectsIds = null,
  schoolCycleId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

    const nameFilter = name !== null ? `&nombre=${name}` : '';
    const startDayFilter = startDay !== null ? `&fecha_inicio=${startDay}` : '';
    const endDayFilter = endDay !== null ? `&fecha_fin=${endDay}` : '';
    const schoolLevelFilter =
      schoolLevel !== null ? `&nivel_educativo=${schoolLevel}` : '';
    const officialSubjectsIdsFilter =
      officialSubjectsIds != null
        ? `&materias_curriculares=${officialSubjectsIds}`
        : '';
    const extraSubjectsIdsFilter =
      extraSubjectsIds !== null
        ? `&materias_extracurriculares=${extraSubjectsIds}`
        : '';
    const schoolCycleIdFilter =
      schoolCycleId !== null ? `&ciclo_escolar=${schoolCycleId}` : '';

    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/plan-estudios?'.concat(
        institutionFilter,
        systemStatusFilter,
        nameFilter,
        startDayFilter,
        endDayFilter,
        schoolLevelFilter,
        officialSubjectsIdsFilter,
        extraSubjectsIdsFilter,
        schoolCycleIdFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los planes de estudio. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener planes de estudio. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener planes de estudio. ' + error,
      count: 0,
    };
  }
};

export const postSyllabus = async (syllabus) => {
  try {
    const response = await postItem(url, syllabus);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo crear el plan de estudio. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar crear plan de estudio. ' + error,
    };
  }
};

export const putSyllabus = async (syllabus) => {
  try {
    const { id } = syllabus;
    const response = await putItem(`${url}/${id}`, syllabus);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo actualizar el plan de estudio. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar actualizar planes de estudio. ' + error,
    };
  }
};

export const delSyllabus = async (id) => {
  try {
    const response = await deleteItem(`${url}`, id);
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo eliminar el plan de estudio. ' + response.e,
      };
    }
    return { ok: true, data: 'Exito al eliminar plan de estudio' };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar eliminar plan de estudio. ' + error,
    };
  }
};

export const reactivateSyllabus = async (syllabusId) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/reactivar-plan-estudios',
      {
        id_plan_estudios: syllabusId,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo reactivar el plan de estudios. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar recrear plan de estudios. ' + error,
    };
  }
};

export const duplicateSyllabus = async (
  plan = {
    id: null,
    name: '',
  }
) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/duplicar-plan-estudios',
      {
        id_plan_estudios: plan.id,
        nombre_nuevo: plan.name,
      }
    );
    if (response.e) {
      return {
        ok: false,
        message: 'No se pudo duplicar el plan de estudios. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar duplicar plan de estudios. ' + error,
    };
  }
};

export const fetchSubjects = async ({
  institutionId = null,
  name = null,
  employeeId = null, // Titular materia
  area_propedeutica = null,
  campos_formativos = null,
  criterios_evaluacion = null,
  extracurricular = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = name !== null && name !== '' ? `&nombre=${name}` : '';
    const employeeIdFilter =
      employeeId !== null ? `&titular_materia=${employeeId}` : '';
    const area_propedeuticaFilter =
      area_propedeutica !== null
        ? `&area_propedeutica=${area_propedeutica}`
        : '';
    const campos_formativosFilter =
      campos_formativos !== null
        ? `&campos_formativos=${campos_formativos}`
        : '';
    const criterios_evaluacionFilter =
      criterios_evaluacion !== null
        ? `&criterios_evaluacion=${criterios_evaluacion}`
        : '';
    const extracurricularFilter =
      extracurricular !== null ? `&extracurricular=${extracurricular}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/materia?'.concat(
        institutionFilter,
        nameFilter,
        employeeIdFilter,
        area_propedeuticaFilter,
        campos_formativosFilter,
        criterios_evaluacionFilter,
        extracurricularFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las materias. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener materias. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener materias. ' + error,
      count: 0,
    };
  }
};

export const fetchSubject = async (subjectId) => {
  try {
    const response = await getItem(`/app-personas/materia/${subjectId}`);
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e ? e : detail ? detail : 'Error al obtener materia por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener materia por id. ' + error,
    };
  }
};

export const fetchSubjectStudents = async ({
  studentId = null,
  subjectTeacherId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const studentIdFilter = studentId ? `&alumno=${studentId}` : '';
    const subjectIdFilter = subjectTeacherId ? `&materia_profesor=${subjectTeacherId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/materia-alumno?'.concat(
        systemStatusFilter,
        studentIdFilter,
        subjectIdFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las materiasAlumno. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener materiasAlumno. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener materiasAlumno. ' + error,
      count: 0,
    };
  }
};
export const fetchSubjectTeacher = async ({
  teacherId = null,
  subjectId = null,
  systemStatus = null,
  limit = null,
  offset = 0,
}) => {
  try {
    const studentIdFilter = teacherId ? `&profesor=${teacherId}` : '';
    const subjectIdFilter = subjectId ? `&materia=${subjectId}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `estatus_sistema=${systemStatus}` : '';
    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/materia-alumno?'.concat(
        systemStatusFilter,
        studentIdFilter,
        subjectIdFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener las materiasAlumno. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener materiasAlumno. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener materiasAlumno. ' + error,
      count: 0,
    };
  }
};
export const fetchSubjectStudent = async (subjectStudentId) => {
  try {
    const response = await getItem(
      `/app-personas/materia-alumno/${subjectStudentId}`
    );
    const { e, detail } = response;
    if (e || detail) {
      return {
        ok: false,
        message: e
          ? e
          : detail
          ? detail
          : 'Error al obtener materiaAlumno por id',
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message: 'Error al intentar obtener materiaAlumno por id. ' + error,
    };
  }
};

export const fetchSchoolLevels = async ({
  institutionId = null,
  name = null,
  systemStatus = null,
  limit = 50,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = name !== null ? `&nombre=${name}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/nivel-educativo?'.concat(
        institutionFilter,
        nameFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los niveles educativos. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener niveles educativos. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener niveles educativos. ' + error,
      count: 0,
    };
  }
};

export const fetchEvaluationPeriods = async ({
  institutionId = null,
  name = null,
  startDate = null,
  endDate = null,
  systemStatus = null,
  limit = 50,
  offset = 0,
}) => {
  try {
    const institutionFilter = institutionId
      ? `institucion_educativa=${institutionId}`
      : '';
    const nameFilter = name !== null ? `&dato=${name}` : '';
    const startDateFilter =
      startDate !== null ? `&fecha_inicio=${startDate}` : '';
    const endDateFilter = endDate !== null ? `&fecha_fin=${endDate}` : '';
    const systemStatusFilter =
      systemStatus !== null ? `&estatus_sistema=${systemStatus}` : '';

    const limitFilter = limit !== null ? `&limit=${limit}` : '';
    const offsetFilter = offset !== 0 ? `&offset=${offset}` : '';
    const response = await getItem(
      '/app-personas/filters/periodo-evaluacion?'.concat(
        institutionFilter,
        nameFilter,
        startDateFilter,
        endDateFilter,
        systemStatusFilter,
        limitFilter,
        offsetFilter
      )
    );
    const { results, count, e } = response;
    if (e) {
      return {
        ok: false,
        message: 'No se pudieron obtener los periodos de evaluación. ' + e,
        count: 0,
      };
    }
    return { ok: true, data: results, count: count };
  } catch (error) {
    console.error('Error al obtener periodos de evaluación. ', error);
    return {
      ok: false,
      message: 'Error al intentar obtener periodos de evaluación. ' + error,
      count: 0,
    };
  }
};

export const getSubjectHolder = async (employeeId) => {
  try {
    const employeeData = await getItem(`/app-personas/empleado/${employeeId}`);
    if (employeeData.e) {
      return { ok: false, message: 'Error al obtener empleado' };
    }
    return { ok: true, data: employeeData };
  } catch (error) {
    return { ok: false, message: error };
  }
};

export const getNameOfSubjectHolder = async (holder) => {
  try {
    const personalData = await getItem(
      `/app-personas/datos-personales/${holder.datos_personales}`
    );
    const fullName = personalData.e ? 'Sin nombre' : getFullName(personalData);
    return fullName;
  } catch (error) {
    console.error('Error al intentar obtener titular de grupo');
    return 'Sin datos personales';
  }
};

export const getTrainingField = async (trainingFieldId) => {
  try {
    const responseData = await getItem(
      `/app-personas/campo-formativo/${trainingFieldId}`
    );
    if (responseData.e) {
      return { ok: false, message: 'Error al obtener campo formativo' };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error };
  }
};

export const getPropaedeuticArea = async (pAreaId) => {
  try {
    const responseData = await getItem(
      `/app-personas/area-propedeutica/${pAreaId}`
    );
    if (responseData.e) {
      return { ok: false, message: 'Error al obtener campo area propedeutica' };
    }
    return { ok: true, data: responseData };
  } catch (error) {
    return { ok: false, message: error };
  }
};

export const postAssignSyllabusToGroup = async (
  assignData = {
    id_grupo: null,
    id_plan_estudios: null,
  }
) => {
  try {
    const response = await postItem(
      '/app-personas/helpers/asignar-plan-estudios-grupo',
      assignData
    );
    if (response.e) {
      return {
        ok: false,
        message:
          'No se pudo asignar el plan de estudios al grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar asignar el plan de estudios al grupo. ' + error,
    };
  }
};

export const deleteAssignSyllabusToGroup = async (
  assignData = {
    id_grupo: null,
    id_plan_estudios: null,
  }
) => {
  try {
    const response = await deleteItemWithOptions(
      '/app-personas/helpers/asignar-plan-estudios-grupo',
      { data: { id_grupo: assignData.id_grupo } }
    );
    if (response.e) {
      return {
        ok: false,
        message:
          'No se pudo desasignar el plan de estudios al grupo. ' + response.e,
      };
    }
    return { ok: true, data: response };
  } catch (error) {
    return {
      ok: false,
      message:
        'Error al intentar desasignar el plan de estudios al grupo. ' + error,
    };
  }
};
